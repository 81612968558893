body {
  font-size: 16px;
  font-family: Arial;
}

.page-container {
  margin: 0 auto;
  max-width: 640px;
}

.input-row {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 4px;
}

.solid-input {
  background-color: white;
  border: solid 1px gray;
  border-radius: 5px;
  color: black;
  font-family: inherit;
  font-size: inherit;
  padding: 3px 6px;
}

.solid-button {
  background-color: #eee;
  border: solid 1px gray;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  height: 36px;
  padding: 3px 12px;
  text-decoration: none;
}

.solid-button:disabled {
  background-color:  white;
  border-color: #ccc;
  color: gray;
}

.link {
  background: none;
  border: none;
  color: rgb(0, 0, 238);
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
}

.site-header {
  display: flex;
  justify-content: space-between;
}

.site-header__title {
  margin: 0;
}

.site-nav__list {
  margin: 0;
  padding: 0;
}

.site-nav__list-item {
  display: inline-block;
  margin-right: 10px;
}

.bookmarks-list {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.bookmark {
  border-bottom: solid 1px gray;
  padding: 10px 0;
  overflow: hidden;
}

.bookmark__title {
  font-size: 20px;
  font-weight: bold;
}

.bookmark__url {
  color: gray;
}

.bookmark__source {
  color: gray;
}

.tag-list {
  margin: 0;
  padding: 5px 0;
}

.tag-list__tag {
  background-color: #ddf;
  border-radius: 5px;
  color: black;
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 3px 6px;
}
